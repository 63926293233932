<template>
  <div class="content">
    <div class="content-box lfont">
      <importData />
    </div>
    <hr />
    <div class="content-box lfont">
      <importHoliday />
    </div>

  </div>
</template>

<script>
import importData from "@/components/employee/import/employeeInfo";
import importHoliday from "@/components/employee/import/importHoliday";
export default {
  components: {
    importHoliday,
    importData,
  },
};
</script>

<style scoped lang="scss">
.content {
  background: white;
  padding: 10px;

  .content-box {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 30px;
    margin-top: 10px;
  }
}

.main-container {
  margin: 10px !important;
  padding-bottom: 0px !important;
}

.section-action {
  height: auto;
  margin-top: 20px;
}

.section-form-content {
  margin-top: 25px !important;
}

.highlight--schedule {
  .schedule--byEmployee {
    background-color: rgba(0, 168, 255, 0.15);
    color: #719aff;
    padding: 3px 12px;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }

  .schedule--byPosition {
    background-color: rgba(239, 87, 119, 0.2);
    color: #f82c80;
    padding: 3px 12px;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }
}

.container-report {
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 40px 0;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  border: 1px solid #eeeeee;

  h1 {
    font-family: $font-family;
    font-size: 1.5rem;
    background-color: #ffffff;
    color: blue;
  }

  .card-report {
    width: 100%;
    height: auto;
    overflow: hidden;
    overflow-x: auto;
    //background-color: #FFFFFF;
    padding: 15px 0;
    font-family: $font-family;
    background-color: #ffffff;
    margin-bottom: 5px;
  }
}

.card-color-detail {
  margin-bottom: 25px !important;

  .color-detail {
    width: 100%;
    height: 80%;
    display: flex;
    border: 1px solid #eeeeee;
    align-items: center;
    border-radius: 16px;
    padding: 10px 10px;
    font-size: 14px !important;

    .circle-color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: red;
    }

    p {
      font-family: $font-family;
      margin-bottom: 0;
      padding-left: 20px;
    }

    .standard {
      background-color: #ffffff;
      border: 1px solid #000000;
    }

    .is-leave {
      background-color: #80acf4;
    }

    .is-absence {
      background-color: red;
    }

    .is-skip {
      background-color: #fd9644;
    }

    .is-time-difference {
      background-color: #2f3542;
    }
  }
}

#mytable {
  font-family: $font-family;
  text-transform: capitalize;
  font-size: 12px !important;

  td {
    border: 1px solid;
  }

  .roster-chip {
    color: white;
    background: rgb(225, 175, 39) !important;
  }

  .emp-roster:hover {
    background: rgb(212, 212, 212);
  }

  .sum-work-sheet {
    color: blue;
    border: 1px solid #eae9e9;
    text-align: center;
    padding: 5px;
  }

  .ma-2 {
    font-size: 12px !important;
    margin: 2px !important;
  }
}

.td-part-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border: 1px solid #000000;
}

.show-file-name {
  color: red;
}
</style>