<template>
    <div>
        <h3>
            <i class="fa fa-sun" aria-hidden="true"></i>
            {{ $t("Employee.import.import_leave") }}
        </h3>
        <v-row dense>
            <v-col cols="12" md="8" lg="8">
                <v-row>
                    <v-col cols="12" md="4" lg="4">
                        <label class="label-input">{{
                            $t("Employee.search_filter")
                        }}</label>
                        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense
                            prepend-inner-icon="mdi-magnify" placeholder="..." v-model="searchItem" hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" lg="4">
                        <label class="label-input">{{ $t("Employee.dept") }}</label>
                        <v-select class="btn btn-color btn-border btn-border-color" outlined dense
                            :placeholder="$t('Employee.dept')" hide-details :items="listDepartment" item-value="id"
                            item-text="name" v-model="department_id" @change="handleDepartment($event)">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="4" lg="4">
                        <label class="label-input">{{ $t("Employee.branch") }}</label>

                        <v-select class="btn btn-color btn-border btn-border-color" outlined dense
                            :placeholder="$t('Employee.branch')" hide-details :items="listBranch" item-value="id"
                            item-text="branch_name" v-model="branch_id" @change="handleBranch($event)">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="12" lg="12">
                        <label class="label-input">{{ $t("Employee.holiday") }}</label>
                        <v-select outlined item-text="name" item-value="id" multiple :items="holiday"
                            v-model="holiday_id">
                            <template v-slot:prepend-item>
                                <v-list-item ripple @click="onSelectAllEmployee">
                                    <v-list-item-action>
                                        <v-icon :color="holiday_id.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title> Select All </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                        </v-select>
                        <a @click="downloadLeaveTemplate"><i class="fas fa-cloud-download"
                                style="margin-right: 5px;"></i>{{
                                    $t("Employee.download_template") }}</a>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="4">
                <div class="choose-image">
                    <div class="show-image">
                        <i class="fas fa-cloud-upload-alt"></i>
                        <input @change="PreviewLeaveFile" type="file" ref="fileInput" class="input-file-image" />
                    </div>
                </div>
                <span v-if="showLeaveFile" class="show-file-name">File Name:
                    <span class="text-primary">{{ showLeaveFile }}</span></span><br />
                <v-btn color="blue-grey" class="ma-2 white--text" @click="importLeaveData" v-if="showLeaveFile">
                    Import
                    <v-icon right dark> mdi-cloud-upload </v-icon>
                </v-btn>
                <p class="text-danger" v-for="(item, i) in server_leave_errors" :key="i">
                    {{ server_leave_errors[`${i}`] }}
                </p>
                <div v-if="isLeaveSuccess">
                    <v-alert dense text type="success"> Import success... </v-alert>
                </div>
                <div v-if="nodataLeave">
                    <v-alert dense text type="error"> ບໍ່ມີຂໍ້ມູນ... </v-alert>
                </div>
            </v-col>
        </v-row>
        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import Loading from "@/components/Loading";
export default {
    components: {
        Loading,
    },
    data() {
        return {
            searchItem: "",
            file: "",
            showFile: "",
            showLeaveFile: "",
            importFile: false,
            importLeaveFile: false,
            isLoading: false,
            server_leave_errors: {},
            isLeaveSuccess: false,
            department_id: "",
            branch_id: "",
            listDepartment: [],
            listBranch: [],
            nodataLeave: false,
            holiday: [],
            holiday_id: [],
            importingEmployee: [],
            qtyImport: 0,
            server_errors: {
                filter: "",
                holiday_id: "",
                department_id: "",
                branch_id: "",
            }
        };
    },
    computed: {
        selectAllEmployees() {
            return this.holiday_id.length === this.holiday.length;
        },
        selectSomeEmployee() {
            return this.holiday_id.length > 0 && !this.selectAllEmployees;
        },
        icon() {
            if (this.selectAllEmployees) return "mdi-close-box";
            if (this.selectSomeEmployee) return "mdi-minus-box";
            return "mdi-checkbox-blank-outline";
        },
    },
    methods: {
        onSelectAllEmployee() {
            this.$nextTick(() => {
                if (this.selectAllEmployees) {
                    this.holiday_id = [];
                } else {
                    this.holiday_id = this.holiday.slice();
                }
            });
        },
        importLeaveData() {
            let formData = new FormData();
            formData.append("file", this.importLeaveFile);
            this.isLoading = true;
            this.$axios
                .post(`company/import/employee/leave`, formData)
                .then((res) => {
                    if (res.data.code === 200) {
                        this.clearFileInput();
                        this.isLeaveSuccess = true;
                        this.showLeaveFile = false;
                        this.server_leave_errors = [];
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.clearFileInput();
                    this.isLoading = false;
                    this.showLeaveFile = false;
                    const obj = error.response.data.errors;
                    for (let [key, value] of Object.entries(obj)) {
                        //let text = value[0].replace(/[0-9]/g + ".", "");
                        let text = value[0];
                        let cut = "";
                        for (var i = 0; i < text.length; i++) {
                            if (!isNaN(text.charAt(i))) {
                                if (text.charAt(i) != " ") {
                                    cut = text.charAt(i);
                                    break;
                                }
                            }
                        }
                        this.server_leave_errors[key] = text.replace(`${cut}.`, "");
                    }
                });
        },
        handleDepartment(department_id) {
            this.department_id = department_id;
        },

        handleBranch(branch_id) {
            this.branch_id = branch_id;
        },
        fetchDepartment() {
            this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
                if (res.status === 200) {
                    this.listDepartment = res.data.data;
                }
            });
        },
        fetchBranch() {
            this.$axios
                .get(`company/list/company/branches`)
                .then((res) => {
                    if (res) {
                        this.listBranch = res.data.companyBranches;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        downloadLeaveTemplate() {
            const holiday_id = this.holiday_id.map(item => item.id);
            this.isLoading = true;
            this.$axios
                .get(`company/export/employee/leave/template`, {
                    responseType: "blob",
                    params: {
                        filter: this.searchItem,
                        department_id: this.department_id,
                        branch_id: this.branch_id,
                        holiday_id: holiday_id,
                    },
                })
                .then((res) => {
                    this.isLoading = false;
                    const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileUrl;
                    fileLink.setAttribute("download", "import-employee-leave.xlsx");
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.nodataLeave = true;
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, "ເລືອກວັນພັກທີ່ຕ້ອງການດາວໂຫຼດກ່ອນ !!");
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                });
        },

        fetchHoliday() {
            this.$axios
                .get(`company/list/holidays`)
                .then((res) => {
                    if (res.status === 200) {
                        this.holiday = res.data.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        clearFileInput() {
            this.$nextTick(() => {
                this.$refs.fileInput.value = '';
            });
        },

        PreviewLeaveFile: function (event) {
            const input = event.target;

            const file = input.files[0];
            const fileType = file["type"];
            const validImageTypes = [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ];
            if (!validImageTypes.includes(fileType)) {
                this.$notification.ShowErrors(
                    "top-right",
                    "danger",
                    3000,
                    "image must be csv,xlsx"
                );
            } else if (input.files && input.files[0]) {
                this.importLeaveFile = input.files[0];
                this.showLeaveFile = this.importLeaveFile.name;
            }
        },
    },
    created() {
        this.fetchDepartment();
        this.fetchBranch();
        this.fetchHoliday();
    },
}

</script>